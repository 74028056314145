@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker.css';

.cdx-input {
  border: 1px solid rgba(201, 201, 204, 0.48);
  -webkit-box-shadow: inset 0 1px 2px 0 rgba(35, 44, 72, 0.06);
  box-shadow: inset 0 1px 2px #232c480f;
  border-radius: 3px;
  padding: 10px 12px;
  outline: none;
  width: 100%;
  -webkit-box-sizing: border-box;
  visibility: hidden;
  box-sizing: border-box;
}

h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h2 {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h3 {
  display: block;
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

h6 {
  display: block;
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.ce-conversion-toolbar {
  color: black;
}

.ce-popover__item {
  display: flex;
  align-items: center;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  margin-top: 9px;
  border-radius: 2px;
  padding: 2px;
}

.ce-popover__no-found {
  color: white;
}

.ce-editorjsColumns_col {
  border: 1px solid #eee;
  border-radius: 5px;
  gap: 10px;
  padding-top: 10px;
}

.ce-editorjsColumns_col:focus-within {
  box-shadow: 0 6px 18px #e8edfa80;
}
.cdp-button {
  cursor: pointer;
  padding: 8px 8px 9px 9px;
  display: flex;
  font-weight: 700;
  border-radius: 3px;
  color: var(--natural-25, #fcfcfd);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 32px;
  height: 32px;
  background: #2d34b0;
  border-radius: 5px;
  align-items: center;
  margin-right: 8px;
  font-size: 15px;
  margin-top: 6px;
  margin-left: 4px;
}

.cdp-dropdown-container {
  display: flex;
  width: 162px;
  height: 78px;
  padding: 4px 0px;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  border-radius: 8px;
  border: 1px solid var(--natural-100, #f2f4f7);
  background: var(--natural-25, #fcfcfd);

  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  z-index: 1;
  top: 50px;
}

.cdp-dropdown-container div {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--natural-900, #101828);

  /* Caption */
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.048px;
  border-bottom: 1px solid var(--natural-100, #f2f4f7);
}

.cdp-dropdown-container div:hover {
  background-color: #ddd;
}

.cdp-button:hover + .cdp-dropdown-container {
  display: block;
}

.disbled-icons {
  pointer-events: none;
}

.cdx-input {
  display: none;
}
